import { getAppUrl } from 'config';

export const apis = {
  DOCUMENTS: '/api/canvas/documents',
  DOCUMENTS_SIGNED: '/api/canvas/documents/sign',
  TOKEN: '/api/canvas/twilio',
  VIDEO: '/api/canvas',
  JOIN_VIDEO: '/api/canvas/join',
  CUSTOMER: '/api/canvas/customer',
  BROKER_VIDEO: '/api/canvas/broker',
  BROKER_STARTED_SESSION: '/api/canvas/broker/started',
  BROKER_VIDEO_CREATE: '/api/canvas/broker/create',
  OUTPUT_LATEST: '/api/canvas/output/latest',
  SERVICING: '/api/canvas/loan-application/servicing',
  LOAN_APPLICATION: '/api/canvas/loan-application',
};

export const images = {
  BIN: '/images/canvas/bin.svg',
  CALL_END: '/images/canvas/call-end.svg',
  ERASER: '/images/canvas/eraser.svg',
  SPOTLIGHT: '/images/canvas/spotlight.svg',
  MOVE: '/images/canvas/move.svg',
  PENCIL: '/images/canvas/pencil.svg',
  SHARE: '/images/canvas/share.svg',
  ZOOM_IN: '/images/canvas/zoom-in.svg',
  ZOOM_OUT: '/images/canvas/zoom-out.svg',
  FOLLOW: '/images/canvas/follow.svg',
  CAMERA: '/images/canvas/camera.svg',
  TABLET: '/images/canvas/tablet.svg',
  ARROW_UP: '/images/canvas/arrow-up.svg',
  MIC: '/images/canvas/mic.svg',
  VIDEO: '/images/canvas/video.svg',
  MIC_WHITE: '/images/canvas/mic-white.svg',
  VIDEO_WHITE: '/images/canvas/video-white.svg',
  MIC_OFF: '/images/canvas/mic-off.svg',
  VIDEO_OFF: '/images/canvas/video-off.svg',
  MIC_OFF_WHITE: '/images/canvas/mic-off-white.svg',
  VIDEO_OFF_WHITE: '/images/canvas/video-off-white.svg',
  COG_WHITE: '/images/canvas/cog-white.svg',
  COG: '/images/canvas/cog.svg',
  ERASER_WHITE: '/images/canvas/eraser-white.svg',
  SPOTLIGHT_WHITE: '/images/canvas/spotlight-white.svg',
  MOVE_WHITE: '/images/canvas/move-white.svg',
  PENCIL_WHITE: '/images/canvas/pencil-white.svg',
  SPEAKER: '/images/canvas/speaker.svg',

  CAMERA_DARK: '/images/canvas/camera-dark.svg',
  TABLET_DARK: '/images/canvas/tablet-dark.svg',

  CAMERA_DETAILED: '/images/canvas/camera-detailed.svg',
  UPLOAD_CAMERA: '/images/canvas/upload-camera.svg',

  BACKGROUND: '/images/canvas/background.svg',
  ALERT: '/images/canvas/alert.svg',
  IMAGE_UPLOAD: '/images/canvas/image-upload.svg',
  IMAGE_UPLOAD_ICON: '/images/canvas/image-upload-icon.svg',

  POINTER_WHITE: '/images/canvas/pointer-white.svg',
  POINTER: '/images/canvas/pointer.svg',
  TEXT_WHITE: '/images/canvas/text-white.svg',
  TEXT: '/images/canvas/text.svg',

  TRUE_LOGO: '/images/canvas/true-logo.png',
  TRUE_CANVAS: '/images/canvas/true-canvas.svg',
  MOBILE_ROTATE: '/images/canvas/mobile-rotate.svg',
  MOBILE_SCROLL: '/images/canvas/mobile-scroll.svg',
  BURGER: '/images/canvas/burger.svg',

  DISCOVERY: '/images/canvas/discovery.svg',
  DISCOVERY_WHITE: '/images/canvas/discovery-white.svg',

  CLOSE_SLICE: '/images/canvas/close-slice.svg',
  PLUS: '/images/canvas/plus.svg',
  PAGE_SETTINGS: '/images/canvas/page-settings.svg',
  PAGE_ADD: '/images/canvas/page-add.svg',
};

export const pages = {
  VIDEO: '/canvas',
  BROKER_VIDEO: '/canvas/broker',
  BROKER_JOIN: '/canvas/broker/join',
  START_SESSION: '/canvas',
  ENTER_CODE: '/canvas/code',
  START_SESSION_CALCULATOR: '/canvas/calculator',
  CUSTOMER_CAMERA: '/canvas/camera',
  BROKER_TABLET: '/canvas/tablet',
  RATE_SESSION: '/canvas/rate',
};

const APP_URL = getAppUrl();

export function getBrokerVideoUrl(sessionId: string): string {
  return `${APP_URL}${pages.BROKER_VIDEO}/${sessionId}`;
}

export function getCustomerVideoUrl(whiteboardOnly: boolean): string {
  if (whiteboardOnly) {
    return `${APP_URL}${pages.ENTER_CODE}?wo=true`;
  }
  return `${APP_URL}${pages.ENTER_CODE}`;
}
