import dynamic from 'next/dynamic';
import thinkingLottie from 'components/lottie/Thinking.json';
import thinkingWhiteLottie from 'components/lottie/thinking-white.json';
import * as CSS from 'csstype';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core';

const Lottie = dynamic(() => import('react-lottie-player'));

type Props = {
  className?: string;
  isWhite?: boolean;
  size?: 'large' | 'default' | CSS.WidthProperty<string | number>;
  fullPage?: boolean;
  noHeader?: boolean;
};

const useStyles = makeStyles<Theme, Pick<Props, 'size' | 'noHeader'>>({
  spinner: (props) => ({
    height: props.size,
    width: props.size,
  }),
  root: {
    height: (props) => (props.noHeader ? '100vh' : 'calc(100vh - 80px)'),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function Spinner(props: Props) {
  let s = props.size;
  if (s === 'large') {
    s = '50px';
  } else if (s === 'default' || s === undefined || s === null) {
    s = '30px';
  }
  const classes = useStyles({ size: s, noHeader: props.noHeader });

  const spinner = (
    <Lottie
      className={cx(classes.spinner, props.className)}
      style={{
        width: s,
        height: s,
      }}
      play
      loop
      animationData={props.isWhite ? thinkingWhiteLottie : thinkingLottie}
      audioFactory={null}
    />
  );

  if (props.fullPage) {
    return <div className={classes.root}>{spinner}</div>;
  }

  return spinner;
}
