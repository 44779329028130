/* eslint-disable mui-unused-classes/unused-classes */
import { Hidden, makeStyles } from '@material-ui/core';
import assert from 'assert';
import { MONTBLANC_FONT_FAMILY, ROBOTO_FONT_FAMILY, Colors } from 'components/Layout/theme';
import React, { CSSProperties, FC, useMemo } from 'react';
import cx from 'classnames';

type TypeStyle =
  | 'd1/mobile'
  | 'd1/tablet'
  | 'd1/desktop'
  | 'd2/mobile'
  | 'd2/tablet'
  | 'd2/desktop'
  | 'd3/mobile'
  | 'd3/tablet'
  | 'd3/desktop'
  | 'd4/mobile'
  | 'd4/tablet'
  | 'd4/desktop'
  | 'd5/mobile'
  | 'd5/tablet'
  | 'd5/desktop'
  | 'd6/mobile'
  | 'd6/tablet'
  | 'd6/desktop'
  | 'd7/mobile'
  | 'd7/tablet'
  | 'd7/desktop'
  | 'sub1/mobile'
  | 'sub1/tablet'
  | 'sub1/desktop'
  | 'sub2/mobile'
  | 'sub2/tablet'
  | 'sub2/desktop'
  | 'b1/mobile'
  | 'b1/tablet'
  | 'b1/desktop'
  | 'b2/mobile'
  | 'b2/tablet'
  | 'b2/desktop'
  | 'b3/mobile'
  | 'b3/tablet'
  | 'b3/desktop'
  | 'cap1/mobile'
  | 'cap1/tablet'
  | 'cap1/desktop'
  | 'cap2/mobile'
  | 'cap2/tablet'
  | 'cap2/desktop'
  | 'button1/mobile'
  | 'button1/tablet'
  | 'button1/desktop'
  | 'button2/mobile'
  | 'button2/tablet'
  | 'button2/desktop'
  | 'none';

type ResponsiveTypeStyle =
  | 'd1'
  | 'd2'
  | 'd3'
  | 'd4'
  | 'd5'
  | 'd6'
  | 'd7'
  | 'sub1'
  | 'sub2'
  | 'b1'
  | 'b2'
  | 'b3'
  | 'cap1'
  | 'cap2'
  | 'button1'
  | 'button2'
  | 'none';
export type TextComponent = 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type TypographyCSSProperties = Pick<
  CSSProperties,
  'fontFamily' | 'fontStyle' | 'fontWeight' | 'fontSize' | 'lineHeight' | 'letterSpacing'
>;

export const TYPOGRAPHY: Record<string, TypographyCSSProperties> = {
  ROBOTO: {
    fontFamily: ROBOTO_FONT_FAMILY,
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  MONTBLANC: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D1_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '108px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D1_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '96px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D1_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '80px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D2_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '96px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D2_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '80px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D2_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
  },
  D3_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '80px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D3_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D3_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D4_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '64px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D4_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D4_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D5_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D5_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D5_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '115%',
    letterSpacing: '-0.02em',
  },
  D6_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  D6_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  D6_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  D7_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  D7_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  D7_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '130%',
    letterSpacing: '-0.02em',
  },
  SUB1_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '19px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  SUB1_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  SUB1_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  SUB2_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  SUB2_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  SUB2_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  B1_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '19px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B1_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B1_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B2_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B2_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B2_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B3_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B3_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  B3_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP1_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP1_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP1_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP2_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP2_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  CAP2_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.01em',
  },
  BUTTON1_DESKTOP: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  BUTTON1_TABLET: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  BUTTON1_MOBILE: {
    fontFamily: MONTBLANC_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  BUTTON2_DESKTOP: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  BUTTON2_TABLET: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  BUTTON2_MOBILE: {
    fontFamily: ROBOTO_FONT_FAMILY,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '0.01em',
  },
  NONE: {},
};

const useStyles = makeStyles((theme) => ({
  d1_desktop: TYPOGRAPHY.D1_DESKTOP,
  d1_tablet: TYPOGRAPHY.D1_TABLET,
  d1_mobile: TYPOGRAPHY.D1_MOBILE,
  d1: {
    ...TYPOGRAPHY.D1_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D1_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D1_DESKTOP,
  },
  d2_desktop: TYPOGRAPHY.D2_DESKTOP,
  d2_tablet: TYPOGRAPHY.D2_TABLET,
  d2_mobile: TYPOGRAPHY.D2_MOBILE,
  d2: {
    ...TYPOGRAPHY.D2_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D2_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D2_DESKTOP,
  },
  d3_desktop: TYPOGRAPHY.D3_DESKTOP,
  d3_tablet: TYPOGRAPHY.D3_TABLET,
  d3_mobile: TYPOGRAPHY.D3_MOBILE,
  d3: {
    ...TYPOGRAPHY.D3_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D3_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D3_DESKTOP,
  },
  d4_desktop: TYPOGRAPHY.D4_DESKTOP,
  d4_tablet: TYPOGRAPHY.D4_TABLET,
  d4_mobile: TYPOGRAPHY.D4_MOBILE,
  d4: {
    ...TYPOGRAPHY.D4_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D4_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D4_DESKTOP,
  },
  d5_desktop: TYPOGRAPHY.D5_DESKTOP,
  d5_tablet: TYPOGRAPHY.D5_TABLET,
  d5_mobile: TYPOGRAPHY.D5_MOBILE,
  d5: {
    ...TYPOGRAPHY.D5_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D5_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D5_DESKTOP,
  },
  d6_desktop: TYPOGRAPHY.D6_DESKTOP,
  d6_tablet: TYPOGRAPHY.D6_TABLET,
  d6_mobile: TYPOGRAPHY.D6_MOBILE,
  d6: {
    ...TYPOGRAPHY.D6_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D6_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D6_DESKTOP,
  },
  d7_desktop: TYPOGRAPHY.D7_DESKTOP,
  d7_tablet: TYPOGRAPHY.D7_TABLET,
  d7_mobile: TYPOGRAPHY.D7_MOBILE,
  d7: {
    ...TYPOGRAPHY.D7_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.D7_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.D7_DESKTOP,
  },
  sub1_desktop: TYPOGRAPHY.SUB1_DESKTOP,
  sub1_tablet: TYPOGRAPHY.SUB1_TABLET,
  sub1_mobile: TYPOGRAPHY.SUB1_MOBILE,
  sub1: {
    ...TYPOGRAPHY.SUB1_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.SUB1_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.SUB1_DESKTOP,
  },
  sub2_desktop: TYPOGRAPHY.SUB2_DESKTOP,
  sub2_tablet: TYPOGRAPHY.SUB2_TABLET,
  sub2_mobile: TYPOGRAPHY.SUB2_MOBILE,
  sub2: {
    ...TYPOGRAPHY.SUB2_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.SUB2_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.SUB2_DESKTOP,
  },
  b1_desktop: TYPOGRAPHY.B1_DESKTOP,
  b1_tablet: TYPOGRAPHY.B1_TABLET,
  b1_mobile: TYPOGRAPHY.B1_MOBILE,
  b1: {
    ...TYPOGRAPHY.B1_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.B1_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.B1_DESKTOP,
  },
  b2_desktop: TYPOGRAPHY.B2_DESKTOP,
  b2_tablet: TYPOGRAPHY.B2_TABLET,
  b2_mobile: TYPOGRAPHY.B2_MOBILE,
  b2: {
    ...TYPOGRAPHY.B2_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.B2_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.B2_DESKTOP,
  },
  b3_desktop: TYPOGRAPHY.B3_DESKTOP,
  b3_tablet: TYPOGRAPHY.B3_TABLET,
  b3_mobile: TYPOGRAPHY.B3_MOBILE,
  b3: {
    ...TYPOGRAPHY.B3_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.B3_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.B3_DESKTOP,
  },
  cap1_desktop: TYPOGRAPHY.CAP1_DESKTOP,
  cap1_tablet: TYPOGRAPHY.CAP1_TABLET,
  cap1_mobile: TYPOGRAPHY.CAP1_MOBILE,
  cap1: {
    ...TYPOGRAPHY.CAP1_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.CAP1_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.CAP1_DESKTOP,
  },
  cap2_desktop: TYPOGRAPHY.CAP2_DESKTOP,
  cap2_tablet: TYPOGRAPHY.CAP2_TABLET,
  cap2_mobile: TYPOGRAPHY.CAP2_MOBILE,
  cap2: {
    ...TYPOGRAPHY.CAP2_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.CAP2_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.CAP2_DESKTOP,
  },
  button1_desktop: TYPOGRAPHY.BUTTON1_DESKTOP,
  button1_tablet: TYPOGRAPHY.BUTTON1_TABLET,
  button1_mobile: TYPOGRAPHY.BUTTON1_MOBILE,
  button1: {
    ...TYPOGRAPHY.BUTTON1_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.BUTTON1_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.BUTTON1_DESKTOP,
  },
  button2_desktop: TYPOGRAPHY.BUTTON2_DESKTOP,
  button2_tablet: TYPOGRAPHY.BUTTON2_TABLET,
  button2_mobile: TYPOGRAPHY.BUTTON2_MOBILE,
  button2: {
    ...TYPOGRAPHY.BUTTON2_TABLET,
    [theme.breakpoints.down('sm')]: TYPOGRAPHY.BUTTON2_MOBILE,
    [theme.breakpoints.up('lg')]: TYPOGRAPHY.BUTTON2_DESKTOP,
  },
  none: { ...TYPOGRAPHY.NONE },
  marginTop_Single: { marginTop: '0.67em', marginBottom: 0 },
  marginTop_Double: { marginTop: '1.5em', marginBottom: 0 },
  marginTop_None: { marginTop: 0, marginBottom: 0 },
}));

export type TypographyVariant = ResponsiveTypeStyle | ExplicitBreakpointStyles;
type ExplicitBreakpointStyles = { xs?: TypeStyle; sm?: TypeStyle; md?: TypeStyle; lg?: TypeStyle; xl?: TypeStyle };
type MarginType = 'none' | 'single' | 'double';

export type TypographyProps = {
  variant: TypographyVariant;
  className?: string;
  textAlign?: CSSProperties['textAlign'];
  textTransform?: CSSProperties['textTransform'];
  marginTop?: MarginType;
  color?: keyof typeof Colors;
  style?: React.CSSProperties;
};

const DynamicComponent: FC<Omit<TypographyProps, 'variant'> & { component: TextComponent }> = ({
  component,
  children,
  textAlign,
  textTransform,
  color,
  ...attr
}) => {
  const colorText = color ? Colors[color] : undefined;
  const style = useMemo(() => ({ textAlign, textTransform, color: colorText }), [textAlign, textTransform, colorText]);

  switch (component) {
    case 'span': {
      return (
        <span style={style} {...attr}>
          {children}
        </span>
      );
    }
    case 'p': {
      return (
        <p style={style} {...attr}>
          {children}
        </p>
      );
    }
    case 'h1': {
      return (
        <h1 style={style} {...attr}>
          {children}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2 style={style} {...attr}>
          {children}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3 style={style} {...attr}>
          {children}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4 style={style} {...attr}>
          {children}
        </h4>
      );
    }
    case 'h5': {
      return (
        <h5 style={style} {...attr}>
          {children}
        </h5>
      );
    }
    default: {
      throw new Error(`Invalid TextComponent type ${component}`);
    }
  }
};

export const Typography: FC<TypographyProps & { component: TextComponent }> = ({
  variant,
  children,
  className,
  marginTop,
  ...attr
}) => {
  const classes = useStyles();

  const toStyleClass = (styleName: string, className: string, marginTop: MarginType = 'single') => {
    const result = classes[styleName.replace('/', '_')];
    assert(result, `Invalid style name ${styleName}`);
    return cx(
      result,
      className,
      { [classes.marginTop_Single]: marginTop === 'single' },
      { [classes.marginTop_Double]: marginTop === 'double' },
      { [classes.marginTop_None]: marginTop === 'none' }
    );
  };

  if (typeof variant === 'string') {
    return (
      <DynamicComponent className={toStyleClass(variant, className, marginTop)} {...attr}>
        {children}
      </DynamicComponent>
    );
  } else {
    return (
      <>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <DynamicComponent className={toStyleClass(variant.xs, className, marginTop)} {...attr}>
            {children}
          </DynamicComponent>
        </Hidden>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <DynamicComponent className={toStyleClass(variant.sm ?? variant.xs, className, marginTop)} {...attr}>
            {children}
          </DynamicComponent>
        </Hidden>
        <Hidden only={['xs', 'sm', 'lg', 'xl']}>
          <DynamicComponent
            className={toStyleClass(variant.md ?? variant.sm ?? variant.xs, className, marginTop)}
            {...attr}
          >
            {children}
          </DynamicComponent>
        </Hidden>
        <Hidden only={['xs', 'sm', 'md', 'xl']}>
          <DynamicComponent
            className={toStyleClass(variant.lg ?? variant.md ?? variant.sm ?? variant.xs, className, marginTop)}
            {...attr}
          >
            {children}
          </DynamicComponent>
        </Hidden>
        <Hidden only={['xs', 'sm', 'md', 'lg']}>
          <DynamicComponent
            className={toStyleClass(
              variant.xl ?? variant.lg ?? variant.md ?? variant.sm ?? variant.xs,
              className,
              marginTop
            )}
            {...attr}
          >
            {children}
          </DynamicComponent>
        </Hidden>
      </>
    );
  }
};

export const H1: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="h1" {...args}>
    {children}
  </Typography>
);

export const H2: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="h2" {...args}>
    {children}
  </Typography>
);

export const H3: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="h3" {...args}>
    {children}
  </Typography>
);

export const H4: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="h4" {...args}>
    {children}
  </Typography>
);

export const H5: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="h5" {...args}>
    {children}
  </Typography>
);

export const Span: FC<TypographyProps> = ({ children, marginTop = 'none', ...args }) => (
  <Typography component="span" marginTop={marginTop} {...args}>
    {children}
  </Typography>
);

export const P: FC<TypographyProps> = ({ children, ...args }) => (
  <Typography component="p" {...args}>
    {children}
  </Typography>
);
