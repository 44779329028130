import { ConversationState } from './models';

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 166,
  height: 218,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export const VIDEO_SESSION_TOKEN_HEADER = 'x-video-session-token';

export const BACKGROUND_WIDTH = 5000;
export const BACKGROUND_HEIGHT = 3000;
export const CANVAS_ZOOM_STEPS = [0.5, 0.75, 1, 1.5, 2, 3, 5, 7.5, 10];
export const CANVAS_MIN_ZOOM = CANVAS_ZOOM_STEPS[0];
export const CANVAS_MAX_ZOOM = CANVAS_ZOOM_STEPS[CANVAS_ZOOM_STEPS.length - 1];
export const UNKNOWN_NAME = 'Anonymous';
export const UNKNOWN_AVATAR_LABEL = '';

export const SAVINGS_EXPERTS_GROUP = 'savings-experts';
export const INITIALISED_SCREEN = { height: 5, width: 5 };

export const DEFAULT_ATTACHMENT_SIZE = {
  width: 200,
  height: 200,
};
export const TEMPLATE_SUFFIX = '_template.';
export const AUTOSAVE_INTERVAL = 60000;

export const DEFAULT_ATTACHMENT_START_POSITIONS = [
  { top: 30, left: 80 },
  { top: 250, left: 80 },
  { top: 30, left: 300 },
  { top: 250, left: 300 },
  { top: 30, left: 520 },
  { top: 250, left: 520 },
];

export const BROKER_JOIN_POLL_INTERVAL = 2_500;
export const A4_SMALL_DIMENSION = 210 * 4;
export const A4_LARGE_DIMENSION = 297 * 4;
export const MINIMUM_VISIBLE_PIXELS_WHEN_SCROLLING_TO_BOTTOM = 80;

export const EMPTY_CONVERSATION: Omit<ConversationState, 'conversationVersion' | 'myParticipantId' | 'sessionId'> = {
  participants: {},
  connectionStatus: 'disconnected',
  foci: {},
  isCustomerFollowing: true,
  pages: {
    elements: {},
    order: [],
  },
  pointers: {},
  spotlight: {
    participantId: null,
    highlight: null,
    pageId: null,
  },
  viewports: {},
};
