import { AxiosError } from 'axios';
import { logError as logErrorFn } from 'logging';
import { useCallback, useEffect, useState } from 'react';
import { isAxiosError, redactAxiosError } from 'utils/redactor';

export const useAsync = <TRequest, TResponse>(
  asyncFn: (input?: TRequest) => Promise<TResponse>,
  immediate = false,
  onSuccess?: (response?: TResponse) => void,
  logError = true
) => {
  const [result, setResult] = useState<TResponse | null>(null);
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const mutate: (input?: TRequest, throwOnException?: boolean) => Promise<TResponse> = useCallback(
    async (input, throwOnException) => {
      let asyncReturn: TResponse = undefined;
      setIsExecuting(true);
      setResult(null);
      setError(null);

      try {
        asyncReturn = await asyncFn(input);
        setResult(asyncReturn);
        setIsExecuting(false);
        if (onSuccess) {
          onSuccess(asyncReturn);
        }
      } catch (error) {
        setError(error);
        setIsExecuting(false);
        if (logError) {
          if (isAxiosError(error)) {
            if (error.response?.status >= 500) {
              logErrorFn(redactAxiosError(error));
            }
          } else {
            logErrorFn(error);
          }
        }
        if (throwOnException) throw error;
      }
      return asyncReturn;
    },
    [asyncFn]
  );
  const clearError = useCallback(() => setError(null), []);

  useEffect(() => {
    if (immediate) {
      mutate();
    }
  }, [mutate, immediate]);

  return { mutate, result, isExecuting, error: error as AxiosError<{ code?: string; message?: string }>, clearError };
};
