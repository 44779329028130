// @ts-strict

import { pick } from 'ramda';
import type { SetRequired } from 'type-fest';

/**
 * Converts Gtm Tag Props into DOMStringMap that can be set to dataset property
 *
 * @param gtmProps
 * @returns
 */
export function gtmTagPropsToDataset(gtmProps: GtmTagProps): DOMStringMap {
  return {
    'data-event-action': gtmProps?.gtmEventAction || undefined,
    'data-event-category': gtmProps?.gtmEventCategory || undefined,
  };
}

/**
 * Extract GTM Tag Props
 *
 * @param props
 * @returns
 */
export function pickGtmTagProps(props: Partial<GtmTagProps>): GtmTagProps {
  return pick(['gtmEventCategory', 'gtmEventAction'])(props);
}

/**
 * Shortcut function to help generate GTM Tag Props on larger components/views/pages
 *
 * @param p
 * @returns
 */
export function completeGtmTagProps(
  p: SetRequired<GtmTagProps, 'gtmEventCategory'>
): (gtmEventAction: string) => Required<GtmTagProps> {
  return (gtmEventAction: string): Required<GtmTagProps> => {
    return { ...p, gtmEventAction };
  };
}
