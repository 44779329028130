import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Auth from '@aws-amplify/auth';
import { JsonObject } from 'type-fest';
import { generateTracingId } from 'logging/browser';

const client = axios.create();
const anonymousClient = axios.create();

export const correlationIdInterceptor = function (config: AxiosRequestConfig) {
  config.headers['trace-id'] = generateTracingId();
  return config;
};

export const amplifyInterceptor = async (config: AxiosRequestConfig) => {
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();

    if (!config.headers) {
      config.headers = {};
    }

    config.headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (e) {
    // Safely ignore here, API should reject if authentication is required.
  }

  return config;
};

export const amplifyInterceptorOptional = async (config: AxiosRequestConfig) => {
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken().getJwtToken();

    if (!config.headers) {
      config.headers = {};
    }
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (e) {
    //do nothing - optional auth
  }
  return config;
};

client.interceptors.request.use(amplifyInterceptor);

[client, anonymousClient].forEach((c) => {
  c.interceptors.request.use(correlationIdInterceptor);
  c.interceptors.response.use(
    (response: AxiosResponse) => {
      return Promise.resolve(response?.data);
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
});

export const fetcher = <T = JsonObject>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  client.get(url, config);
export const anonymousFetcher = <T = JsonObject>(url: string): Promise<T> => anonymousClient.get(url);

export const post = <T = JsonObject, B = JsonObject>(
  url: string,
  body?: B,
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  config?: AxiosRequestConfig
): Promise<T> => client.post(url, body, { onUploadProgress, ...config });

export const anonymousPost = <T = JsonObject, B = JsonObject>(
  url: string,
  body?: B,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<T> => anonymousClient.post(url, body, { onUploadProgress });

export const put = <T = JsonObject, B = JsonObject>(url: string, body: B, config?: AxiosRequestConfig): Promise<T> =>
  client.put(url, body, config);

export const patch = <T = JsonObject, B = JsonObject>(url: string, body: B, config?: AxiosRequestConfig): Promise<T> =>
  client.patch(url, body, config);

export const del = (url: string): Promise<void> => client.delete(url);
